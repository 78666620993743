.App {
  /* text-align: center; */
  padding: 20px;
}

.App-header {
  display: flex;
  align-items: baseline;

}

.App-title {
  font-size: 1.5em;
  margin-right: 1em;
}


.shop-header {
  display: flex;
  align-items: baseline;
  .shop-name {
    font-weight: 600;
    margin-right: 0.5em;
  }

}

.shop-nav {
  padding: 1em 0;
  font-size: 1.1em;
  a {
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    // margin-right: 0.5em;
    &.active {
      background: #303aa6;
      color: white;
    }
  }

}
