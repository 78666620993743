.product-modifiers-editor {
  padding-bottom: 1em;
  /* margin-bottom: 1em; */
  /* border-bottom: 2px solid gray; */
}

.product-modifiers-editor > .content {
  display: flex;
}
.product-modifiers-editor__header {
  padding-left: 1em;
  color: white;
  background: #0b590d;
  font-weight: 600;
  font-size: 1.2em;
}
.product-modifiers-editor__header button {
  margin-left: 1em;
  color: black;
}

.modgroup-preview {
  border: 1px solid gray;
  padding: 0.3em;
}
.modgroup-preview__modgroups {
  display: flex;
  flex-wrap: wrap;
}

.modgroup-preview label {
  width: 85px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-right: 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modgroup-preview h4 {
  font-weight: 800;
  text-decoration: underline;
  /* text-align:center; */
}
/* .modgroup-preview__modifier-name {

} */

.modifier-nav > a {
  margin-right: 0.5em;
  text-decoration: underline;
}
