@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@100;400;600;800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  /* font-weight: 700; */
}
input, textarea {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
}
* {
  box-sizing: border-box;
}

.App{padding:20px}.App-header{display:flex;align-items:baseline}.App-title{font-size:1.5em;margin-right:1em}.shop-header{display:flex;align-items:baseline}.shop-header .shop-name{font-weight:600;margin-right:.5em}.shop-nav{padding:1em 0;font-size:1.1em}.shop-nav a{padding:.2em .4em;border-radius:.2em}.shop-nav a.active{background:#303aa6;color:#fff}
.sub-menu-inline-form {
  margin: 0.2em 0;
  display: inline-flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  align-items: stretch;

}

.sub-menu-inline-form input[name="name.en"] {
  font-weight: 800;
  font-size: 1.5em;
  width: 8em;
}

.sub-menu {
  border: 1px solid black;
  margin-bottom: 0.5em;
}

.sub-menu header {
  display: flex;
  align-items: center;
  /* background: #7dfa80; */
}

.product {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.product.selected {
  background-color: lightblue;
}

.product-inline-form {
  display: inline-flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  align-items: stretch;
}

input {
  margin: 0.1em;
}

input[name="price"] {
  width: 8em;
  font-weight: 800;
  color: #07008f;
}

.name-input-field {
  width: 15em;
  font-weight: 600 !important;
}

textarea {
  width: 15em;
}

.react-select__container {
  width: 10em;
}

.product-image-dropzone {
  min-width: 100px;
  /* height: auto; */
  min-height: 50px;
  border: 1px dashed gray;
}


.product-modifiers-editor {
  padding-bottom: 1em;
  /* margin-bottom: 1em; */
  /* border-bottom: 2px solid gray; */
}

.product-modifiers-editor > .content {
  display: flex;
}
.product-modifiers-editor__header {
  padding-left: 1em;
  color: white;
  background: #0b590d;
  font-weight: 600;
  font-size: 1.2em;
}
.product-modifiers-editor__header button {
  margin-left: 1em;
  color: black;
}

.modgroup-preview {
  border: 1px solid gray;
  padding: 0.3em;
}
.modgroup-preview__modgroups {
  display: flex;
  flex-wrap: wrap;
}

.modgroup-preview label {
  width: 85px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-right: 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modgroup-preview h4 {
  font-weight: 800;
  text-decoration: underline;
  /* text-align:center; */
}
/* .modgroup-preview__modifier-name {

} */

.modifier-nav > a {
  margin-right: 0.5em;
  text-decoration: underline;
}


