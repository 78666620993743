.sub-menu {
  border: 1px solid black;
  margin-bottom: 0.5em;
}

.sub-menu header {
  display: flex;
  align-items: center;
  /* background: #7dfa80; */
}

.product {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.product.selected {
  background-color: lightblue;
}

.product-inline-form {
  display: inline-flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  align-items: stretch;
}

input {
  margin: 0.1em;
}

input[name="price"] {
  width: 8em;
  font-weight: 800;
  color: #07008f;
}

.name-input-field {
  width: 15em;
  font-weight: 600 !important;
}

textarea {
  width: 15em;
}

.react-select__container {
  width: 10em;
}

.product-image-dropzone {
  min-width: 100px;
  /* height: auto; */
  min-height: 50px;
  border: 1px dashed gray;
}

