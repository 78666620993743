@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@100;400;600;800&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  /* font-weight: 700; */
}
input, textarea {
  font-family: 'Fira Sans Extra Condensed', sans-serif;
}
* {
  box-sizing: border-box;
}
