.sub-menu-inline-form {
  margin: 0.2em 0;
  display: inline-flex;
  flex-wrap: wrap;
  /* align-items: flex-start; */
  align-items: stretch;

}

.sub-menu-inline-form input[name="name.en"] {
  font-weight: 800;
  font-size: 1.5em;
  width: 8em;
}
